import { globalHistory } from "@reach/router";
import { withPrefix } from "gatsby";
import createAuthService from "../../common/auth/auth-service";

export default function SigninCallback() {
  if (typeof window === "undefined") return null;
  const authService = createAuthService();
  authService
    .signinRedirect()
    .then(() => {
      globalHistory.navigate(withPrefix("/"));
    })
    .catch(ex => console.log(ex));

  return null;
}
